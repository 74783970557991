<template>
  <v-container fluid>
    <v-slide-x-transition class="pa-2" mode="out-in">
      <div v-if="selectedView === '1'" key="1">
        <v-flex class="mt-5">
          <h1 class="mb-6">
            Broadcasts
          </h1>
          <v-spacer />
        </v-flex>
        <v-card elevation="0" rounded="xl">
          <v-card-title>
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="40"
              />
            </template>
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
            <v-btn
              class="ml-15"
              dark
              color="green"
              @click="selectedView = '2'"
            >
              New Broadcast
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="broadcasts"
            :search="search"
            :footer-props="footerProps"
            :loading="showProgress"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.toWhom="{ item }">
              <div>{{ mapUser(item.toWhom) }}</div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="viewBroadcast(item); selectedView = '3'"
              >
                mdi-eye
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <div
        v-else-if="selectedView === '2'"
        key="2"
        class="pa-2"
      >
        <v-row class="mt-5">
          <h1 class="mb-6">
            Send Broadcast
          </h1>
          <v-spacer />
          <v-btn
            class="ml-15"
            dark
            color="blue"
            @click="clearForm"
          >
            Clear Form
          </v-btn>
          <v-btn
            class="ml-15"
            dark
            color="orange"
            @click="selectedView = '1'"
          >
            Go back
          </v-btn>
        </v-row>
        <v-row>
          <!-- Upload file here -->
          <v-file-input
            multiple
            label="Image"
            chips
            @change="onAddFiles"
          />
        </v-row>
        <v-row>
          <v-col>
            <div v-if="imgLoading">
              <v-progress-circular
                indeterminate
                color="blue"
              />
            </div>
            <img
              :src="imageUrl"
              width="400"
            >
          </v-col>
        </v-row>
        <v-select
          v-model="toWhom"
          :items="usersToBroadcastToFilter"
          :menu-props="{ maxHeight: '400' }"
          label="Select to whom to send"
          :item-text="item => item.name +' - '+ item.email"
          item-value="id"
          clearable
          multiple
          hint="Select to whom (if no-one selected = send to all)"
          persistent-hint
          @change="usersToBroadcastToFilter = usersToBroadcastTo; searchInput = '';"
        >
          <template v-slot:prepend-item>
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  v-model="searchInput"
                  placeholder="Search"
                  @input="searchItem"
                />
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2" />
          </template>
        </v-select>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="title"
            :counter="50"
            :rules="titleRules"
            label="Title"
            required
          />

          <v-text-field
            v-model="message"
            :counter="250"
            :rules="messageRules"
            label="Message"
            required
          />

          <v-select
            v-model="route"
            :items="items"
            label="Link to"
          />

          <v-dialog
            v-model="dialog"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                class="mr-4"
                v-bind="attrs"
                :disabled="!valid || !title || !message || imgLoading"
                v-on="on"
              >
                <div v-if="btnLoading">
                  <v-progress-circular
                    indeterminate
                    color="blue"
                  />
                </div>
                <div v-else>
                  Submit
                </div>
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h6 grey lighten-2">
                Are you sure you would like to send this to <span class="red--text">{{ toWhom.length ? toWhom.length + ' people' : 'Everyone' }}?</span>
              </v-card-title>

              <v-card-text>
                <div v-if="toWhom.length" class="mt-2">
                  {{ mapUser(toWhom) }} will receive :
                </div>
                <div v-if="imageUrl !== ''">
                  <img
                    :src="imageUrl"
                    width="300"
                  >
                </div>
                <div>
                  <span><b>Title:</b> {{ title }}</span>
                </div>
                <div>
                  <span><b>Message:</b> {{ message }}</span>
                </div>
                <div>
                  <span><b>Notification will be linked to:</b> {{ route || 'No link!' }}</span>
                </div>
              </v-card-text>

              <v-divider />

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  text
                  @click="sendBroadcast"
                >
                  Send! Agreeing that Ondrej is the best!
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-form>
      </div>
      <div
        v-else-if="selectedView === '3'"
        key="3"
      >
        <v-row class="mt-5">
          <h1 class="mb-6">
            Broadcast Details
          </h1>
          <v-spacer />
          <v-btn
            class="ml-15"
            dark
            color="orange"
            @click="selectedView = '1'"
          >
            Go back
          </v-btn>
        </v-row>
        <v-row>
          <v-col v-if="selectedBroadcast.imageUrl !== ''">
            <img
              :src="selectedBroadcast.imageUrl"
              width="300"
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p><b>Title:</b> {{ selectedBroadcast.title }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p><b>Message:</b> {{ selectedBroadcast.warningMessage }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p><b>Date:</b> {{ selectedBroadcast.createdAt }}</p>
          </v-col>
        </v-row>
      </div>
    </v-slide-x-transition>
  </v-container>
</template>

<script>
export default {
  name: 'Broadcasts',
  components: {
  },
  data () {
    return {
      selectedView: '1',
      dialog: false,
      showProgress: false,
      btnLoading: false,
      imgLoading: false,
      pagination: {},
      search: '',
      broadcasts: [],
      usersToBroadcastTo: [],
      usersToBroadcastToFilter: [],
      searchInput: '',
      toWhom: [],
      headers: [
        { text: '#', value: 'id' },
        { text: 'Title', value: 'title' },
        { text: 'Message', value: 'warningMessage' },
        { text: 'Date', value: 'createdAt' },
        { text: 'To Whom', value: 'toWhom' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      footerProps: {
        'items-per-page-options': [10, 30, 50, 100]
      },
      valid: false,
      regToken: '',
      title: '',
      titleRules: [
        v => !!v || 'Title is required',
        v => (v && v.length >= 1) || 'Title must be at least 1 character',
        v => (v && v.length <= 50) || 'Title must be less than 50 characters'
      ],
      message: '',
      messageRules: [
        v => !!v || 'Message is required',
        v => (v && v.length >= 1) || 'Message must be at least 1 character',
        v => (v && v.length <= 250) || 'Message must be less than 250 characters'
      ],
      files: [],
      isError: false,
      errorText: null,
      imageUrl: '',
      selectedBroadcast: {},
      route: '',
      items: [
        {
          text: 'e-shop',
          value: '/e-shop'
        },
        {
          text: 'automation',
          value: '/automation'
        },
        {
          text: 'security',
          value: '/security'
        },
        {
          text: 'main-view',
          value: '/main-view'
        }
      ]
    }
  },
  watch: {
    // toWhom: function (val) {
    //   console.log(val)
    // }
  },
  mounted () {
    this.loadBroadcasts()
    this.loadUsersToBroadcast()
  },
  methods: {
    async loadBroadcasts () {
      this.showProgress = true
      this.$store.dispatch('getBroadcasts').then(response => {
        this.broadcasts = response.data
        this.broadcasts.forEach(item => {
          item.createdAt = new Date(item.createdAt).toLocaleString()
        })
        this.showProgress = false
      })
    },
    loadUsersToBroadcast () {
      this.$store.dispatch('getUsersToBroadcast').then(resp => {
        this.usersToBroadcastTo = resp.data
        this.usersToBroadcastToFilter = resp.data
      })
    },
    sendBroadcast () {
      this.$refs.form.validate()
      if (this.valid) {
        this.btnLoading = true
        const broadcastDetails = {
          title: this.title,
          message: this.message,
          image: this.imageUrl,
          route: this.route,
          toWhom: this.toWhom
        }
        this.$store.dispatch('sendBroadcast', broadcastDetails).then(response => {
          this.showProgress = true
          this.btnLoading = false
          this.selectedView = '1'
          this.loadBroadcasts()
          this.clearForm()
        })
      }
    },
    onAddFiles (files) {
      if (files.length > 0) {
        files.forEach((file) => {
          // window.console.log(file)
          this.imgLoading = true
          this.uploadFileToCloudinary(file).then((fileResponse) => {
            this.files.push(fileResponse)
            this.imageUrl = fileResponse.url
            this.imgLoading = false
            // console.log(fileResponse.url)
          })
        })
      }
    },
    uploadFileToCloudinary (file) {
      return new Promise(function (resolve, reject) {
        // Ideally these to lines would be in a .env file
        const cloudname = 'ddmptzcxy'
        const CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/' + cloudname + '/upload'
        const CLOUDINARY_UPLOAD_PRESET = 'hsmknzm2'

        const formData = new FormData()
        formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET)
        formData.append('folder', 'cloudinary-demo')
        formData.append('file', file)

        const request = new XMLHttpRequest()
        request.open('POST', CLOUDINARY_URL, true)
        request.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

        request.onreadystatechange = () => {
          // File uploaded successfully
          if (request.readyState === 4 && request.status === 200) {
            const response = JSON.parse(request.responseText)
            resolve(response)
          }
          // Not succesfull, let find our what happened
          if (request.status !== 200) {
            const response = JSON.parse(request.responseText)
            const error = response.error.message
            alert('error, status code not 200 ' + error)
            reject(error)
          }
        }
        request.onerror = (err) => {
          alert('error: ' + err)
          reject(err)
        }
        request.send(formData)
      })
    },
    viewBroadcast (item) {
      this.selectedBroadcast = item
    },
    searchItem (e) {
      if (!this.searchInput) {
        this.usersToBroadcastToFilter = this.usersToBroadcastTo
      } else {
        this.usersToBroadcastToFilter = this.usersToBroadcastTo.filter((item) => {
          return item.name.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1
        })
      }
    },
    mapUser (id) {
      const resp = []
      id.forEach(item => {
        const user = this.usersToBroadcastTo.find(i => i.id === item) || null

        resp.push(user ? user.name : null)
      })

      return resp.join(', ')
    },
    clearForm () {
      this.searchInput = ''
      this.toWhom = ''
      this.title = ''
      this.message = ''
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
